.progress {
  accent-color: var(--purple) !important;
  width: 100% !important;
  --bs-progress-bar-bg: var(--purple) !important;

  /* background: linear-gradient(to right, #82CFD0 0%, #82CFD0 50%, #fff 50%, #fff 100%); */
}

input[type="range"]::-webkit-slider-thumb {
  background: red !important;
}

input.progress:before {
  background-color: var(--purple);
  border-radius: 50%;
  content: " " !important;
  height: 15px;
  position: absolute;
  width: 15px;
  top: -5px !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* custom css */
.sidebar-main::-webkit-scrollbar {
  width: 0px;
}

.sidebar-main {
  overflow-y: scroll !important;
  overflow-x: visible;
  height: 100vh;
  padding-bottom: 50px;
}

.modal-open img.scroll-top {
  z-index: 99 !important;
}

.modal-open .filter-btn-col {
  z-index: unset !important;
}

.main.switch .content-col {
  display: none !important;
}

.purple-gradient-line:before {
  content: url(./images/nft-purple-line.png);
  position: absolute;

  left: 0px;
  top: 7px;

}

.yellow-gradient-line:before {
  content: url(./images/nft-yellow-line.png);
  position: absolute;

  left: 0px;
  top: 7px;
}

.purple-green-gradient-line:before {
  content: url(./images/nft-green-purple-line.png);
  position: absolute;
  left: 0px;
  top: 7px;
}

.lavendar-gradient-line:before {
  content: url(./images/nft-lavender-line.png);
  position: absolute;
  left: 0px;
  top: 7px;
}

.home .content-col {
  padding-bottom: 137px;
}

.my-wallet .content-col,
.vote .content-col {
  padding-bottom: 77px;
}

.marketplace .content-col {
  padding-bottom: 60px;
}

/* .marketplace .modal-backdrop.show {
  z-index: 999999;
} */

.play .content-col {
  padding-bottom: 170px;
}

.renting .content-col {
  padding-bottom: 216px;
}

.token .content-col {
  padding-bottom: 180px;
}

.staking .content-col {
  padding-bottom: 95px;
}

.navigation-col {
  position: fixed;
  height: 100vh;
  /* overflow-y: auto; */
  /* overflow: visible; */
}

.navigation-col::-webkit-scrollbar {
  width: 0px;
}

.content-col {
  margin-left: 18.5%;
}

.mobile-display {
  display: none;
}

@font-face {
  font-family: Poppins-Bold;
  src: url("./font/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins-Medium;
  src: url("./font/Poppins-Medium.ttf");

}

@font-face {
  font-family: Titan-One;
  src: url("./font/TitanOne-Regular.ttf");

}

:root {
  /* --blue: #8774FF; */
  --white: #ffffff;
  --purple: #8774FF;
  --purple2: #7D8EE8;
  --text-grey: #C8CBDB;
  --light-yellow: #FFD600;
  --dark-yellow: #FFB800;
  --text-green: #35D4C0;
  --text-lavendar: #A568FF;
  --text-dark-yellow: #FFA800;
  --darkyellow-button-background: linear-gradient(95.92deg, #FFB800 10.19%, #FFA800 98.19%);
  --darkyellow-button-box-shadow: 0px 4px 0px #D8732A;
  --darkyellow-button-background-hover: linear-gradient(95.14deg, #FFC700 0%, #FFA800 100%);
  --purple-button-background: #8774FF;
  --purple-button-background-hover: linear-gradient(95.14deg, #8F93FF 0%, #8774FF 100%);
  --purple-button-box-shadow: 0px 4px 0px #533DDA;
  --top-hover-backgroud: rgba(43, 46, 53, 0.8);
  --box-backrond-2: rgba(52, 55, 62, 0.8);
  --green-btn-background: #2FCBB7;
  --green-btn-box-shadow: 0px 4px 0px #0F8D7D;
  --grey-btn-background: #8B9493;
  --grey-btn-box-shadow: 0px 4px 0px #5E6564;
  --yellow-gradient-background: linear-gradient(92.4deg, #FFA800 0.57%, #FF6B00 97.74%);
  --purple-green-gradient-background: linear-gradient(92.4deg, #35D4C0 0.57%, #8D47F3 97.74%);
  --nft-last-gradeint-background: linear-gradient(92.4deg, #C972FF 0.57%, #8D47F3 97.74%);
  --white-btn-color: #DBE0FF;
  --lavendar-background: linear-gradient(92.4deg, #C972FF 0.57%, #8D47F3 97.74%);
  --yellow-background: #FFA800;
  --yellow-box-shadow: 0px 4px 0px #C76E07;
  --purple-gradient-filter-background: linear-gradient(95.34deg, #7D8EE8 19.1%, #A568FF 91.28%);
  --purple-gradient-filter-box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  --purple-gradient2-filter-background: linear-gradient(95.34deg, #7D8EE8 19.1%, #A568FF 91.28%);
  --purple-gradient2-filter-box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  --purple-green-gradient2-background: linear-gradient(94.16deg, #A568FF 0%, #35D4C0 100%);
}

.main-heading {
  font-family: "Titan-One";
}

.connect-text {
  font-family: 'Poppins-Bold';
}

.font-16-500 {
  font-family: "Poppins-Medium";
}

button:hover {
  transform: translateY(3px);
}

.filter-btn button:hover,
.profile-cal-btn-main button {
  transform: unset !important;
}

button {
  transition: all 0.5s;
}

.text-dark-yellow {
  color: var(--text-dark-yellow) !important;
}

.text-green,
.color-green {
  color: var(--text-green) !important;
}

.font-30 {
  font-size: 30px !important;
  line-height: 40px !important;
}

.text-purple-green,
.text-purple2-green {
  background: linear-gradient(90.35deg, #35D4C0 0.25%, #A568FF 99.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.text-purple {
  color: var(--purple);
}

.text-purple2 {
  color: var(--purple2);
}

.yellow-btn:hover {
  box-shadow: unset;
  background: var(--darkyellow-button-background-hover);

}

button:hover {
  box-shadow: unset !important;
}

.margin-top-box {
  margin-top: -36px;
}

.purple-btn:hover {
  background: var(--purple-button-background-hover);
  box-shadow: unset !important;
  /* margin-bottom: -18px; */
}

.green-btn:hover,
.grey-btn:hover {
  box-shadow: unset !important;
}

.font-24-700 {
  font-family: 'Poppins-Bold';
  font-size: 24px;
  line-height: 36px;

}

.swicth-network-main {
  background-image: url('./images/switch-bg-2.png');
  background-size: cover;
  min-height: 100%;
  height: 100vh;
}

.box {
  background: rgba(43, 46, 53, 0.6);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  /* backdrop-filter: blur(2px); */
  border-radius: 36px;
  /* margin-top: -30px; */
  /* z-index: 999999; */
  position: relative;
}

/* .main-inner {
  max-width: 1440px;
  margin: 0 auto;
} */

.switch-main-box {

  width: 625px;
  /* margin-top: 250px; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* width: max-content; */
  margin-left: auto;
  margin-right: auto;
  /* position: relative; */
  /* display:-webkit-box;
-webkit-box-pack:center;
-webkit-box-align:center;
height: calc(100vh - 160px); */
  position: absolute;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.switch-box {
  padding: 38px 80px 64px 80px;
}

.blue-btn {
  background: var(--purple);
  box-shadow: 0px 4px 0px #533DDA;
  border-radius: 31.5px;
  padding: 12px 44px;
  box-shadow: 0px 4px 0px 0px #533DDA;
  border: unset;
  font-family: 'Poppins-Bold';
  font-size: 24px;
  line-height: 36px;
}

.switch-zig-one {
  top: 0px;
  left: 0px;
  position: absolute;

}

.switch-zig-two {
  top: 39%;
  right: -33px;
  position: absolute;

}

/* connect screen */
.main.connect .connect {
  background-image: url(./images/blue-bg.png);
  background-size: cover;
  min-height: 100vh;
  height: 100%;
  background-position: center center;
  padding-bottom: 60px;
}

.main.connect {
  background-image: unset;
}

.connect-inner-card:hover {
  background: linear-gradient(92.4deg, #7D8EE8 0.57%, #A568FF 97.74%);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  border-radius: 36px;
}

.color-purple {
  color: var(--purple)
}

.font-400-70 {
  font-size: 70px;
  line-height: 80px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.connect-box {
  padding: 42px 68px 60px 68px;
  margin-top: -34px;
}

.connect-box-main {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

img.connect-zig-one {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 99;
  left: -67px;
  top: -72px;
}

img.connect-zig-two {
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 99;
  right: -74px;
  bottom: 15px;
}

.connect-inner-card {
  background: rgba(43, 46, 53, 0.6);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(2px);
  padding: 13px 60px 13px 40px;
  border-radius: 40px;
  width: 487px;
}

.connect-text {
  font-size: 24px;
  line-height: 36px;
}

.font-16-500 {
  font-size: 16px;
  line-height: 24px;
}

/* end connect screen */

/* home */
/* .home-bg-img{
  background-image: url(./images/home-play-img.png);
} */
.home-hamer-img-one {
  top: 14%;
  right: -44px;
}

/* .home-nft-img-mobile{
  display: none;
} */
.sidebar-main {
  transition: all 0.5s;
}

.top-bar-box {
  background: rgba(43, 46, 53, 0.8) !important;
  backdrop-filter: unset !important;
}

.box.top-bar-box.px-5.px-xl-5.px-lg-3.px-md-3.py-3 {
  padding: 14px 28px !important;
}

.top-bar-box:hover {
  background: rgba(43, 46, 53, 0.6) !important;
  cursor: pointer;
}

.nav-toggle-img {
  position: absolute;
  top: 13%;
  right: -15px;
  z-index: 999;
}

.nav-toggle-img {
  cursor: pointer;
}

.sidebar-social-div {
  display: inline-flex;
  gap: 30px;
  justify-content: center;
  padding-top: 19px;
  padding-bottom: 19px;
  width: 100%;
}

.main {
  background-image: url(./images/main-dark-bg.png);
  background-size: cover;
  min-height: 100vh;
  height: 100%;
  background-position: center center;

}

.home .main-inner {
  background-image: url(./images/home-bg.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.main-inner {
  max-width: 1440px;
  margin: 0 auto;
}

/* .my-wallet {
  background-image: url(./images/nft-wallet-bg.png);
  background-size: cover;
  min-height: 100vh;
  height: 100%;
  background-position: top center;
} */
.my-wallet .main-inner {
  background-image: url(./images/my-wallet-bg-2.png);
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 100vh;
  height: 100%;
  background-position: top center;
}

.sidebar-logo {
  width: 170px;
}

.sidebar-main .nav-link,
.mobile-nav-bar .nav-link {
  font-family: 'Poppins-Medium';
  font-size: 18px;
  line-height: 27px;
  color: var(--text-grey);
  padding: 13px 0px 13px 32px !important;
}

.nav-modal .nav-link span {
  font-family: 'Poppins-Medium';
  font-size: 18px;
  line-height: 27px;
  color: var(--text-grey);
}

.nav-modal {
  z-index: 999999 !important;
}

.sidebar-main a.active.nav-link {
  margin-bottom: 10px;
  margin-top: 10px;
}

.side-closed .nav-link {
  padding-left: 0px !important;
  text-align: center;
}

.navigation-col {
  z-index: 99;
  width: 18%;
  /* overflow: visible; */
}

.content-col {
  width: 82%;
}

.top-right-text {
  gap: 30px;
}

/* .side-bar-box{
  padding: 17px;
} */
.sidebar-main a.nav-link.active,
.sidebar-main .nav-link:hover {
  border-radius: 0px 30px 30px 0px;
  color: var(--white) !important;
  font-family: 'Poppins-Bold';
  background: linear-gradient(150deg, #7D8EE8 20%, #8774FF 100%);


}

img.play-starter-second-box-img1 {
  position: absolute;
  top: -155px;
  width: 67.5%;
  left: -175px
}

img.play-starter-second-box-img2 {
  position: absolute;
  right: -42px;
  top: -175px;
  width: 48%;
}

.play-starter-second-box {
  padding-top: 40px;
  padding-bottom: 40px;
}

.play-game-large-heading,
.play-game-small-heading {
  background: linear-gradient(90deg, #E5D3FF 0%, #C8FFF8 97.32%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: 'Titan-One';
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.content-top-bar p {
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  font-family: 'Poppins-Medium';
  font-size: 18px;
  line-height: 27px;

  color: var(--text-grey) !important;
}

.content-top-bar p img {
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;
}

.content-top-bar .top-right-text {
  color: var(--white) !important;
}

.play-game-large-heading {
  font-size: 100px;
  line-height: 114px;
}

.scroll-top {
  position: fixed;
  right: 2%;
  bottom: 40px;
  z-index: 1;
  cursor: pointer;
  z-index: 999999999 !important;
}

.play-game-small-heading {
  font-size: 40px;
  line-height: 46px;
}

.play-game-sub-text {
  font-family: 'Poppins-Medium';
  font-size: 20px;
  line-height: 30px;
  color: #DCFBF7;
}

.purple-gradient-box {
  background: linear-gradient(94.16deg, #A568FF 0%, #35D4C0 100%);
  border-radius: 30px;
  padding: 36px 30px 55px 90px;
}

.yellow-btn {
  padding: 12px 44px !important;
  border: unset !important;
  font-family: 'Poppins-Bold' !important;
  font-size: 24px !important;
  line-height: 36px !important;
  background: var(--darkyellow-button-background) !important;
  box-shadow: var(--darkyellow-button-box-shadow) !important;
  border-radius: 31.5px !important;
}

.main-heading {
  font-size: 70px;
  line-height: 80px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.token-text-white,
.nft-text-white {
  color: var(--white);
  font-family: 'Poppins-medium';
  gap: 100px;
  font-size: 20px;
  line-height: 30px;
  width: 61%;
}

.token-box,
.nft-box {
  /* background-image: url(./images/token-icon.png);
  background-repeat: no-repeat;
  background-position-y: 52%;
  background-size: 30%;
  background-position-x: 90%; */
  margin-top: -36px;
  position: relative;
}

.color-purple {
  color: var(--purple) !important;
}

.color-yellow {
  color: var(--light-yellow) !important;
}

/* .nft-box{
  background-image: url(./images/nft-bg.png);
  margin-top: -30px;
  background-repeat: no-repeat;
  background-position: right;
} */
.purple-btn {
  font-family: 'Poppins-Bold' !important;
  font-size: 24px !important;
  line-height: 36px !important;
  border: unset !important;
  text-align: center !important;
  background: var(--purple-button-background) !important;
  box-shadow: var(--purple-button-box-shadow) !important;
  border-radius: 31.5px !important;
  padding: 12px 44px !important;
}

.home-hamer-icon-one {
  top: -37px;
  right: -25px;
  z-index: 999;
  width: 80px;
}

.home-hamer-icon-two {
  top: 26%;
  left: -33px;
}

.home-eagle-icon {
  top: 76%;
  left: 30%;
}

.home-axe-icon {
  left: -109px;
  top: 93%;
}

.top-right-box {
  width: max-content;
}

.home-token-img {
  width: 29%;
  position: absolute;
  top: 25%;
  right: 14px;
}

.home-nft-img {
  position: absolute;
  top: 0px;
  right: 0px;
}

/* end home */
/* my wallet */
.tab-content {
  /* margin-top: 50px; */
}

/* .search-bar-conatiner {
  width: 50% !important;
} */
.tabs-head,
.token-tabs {
  width: 100%;
}

.tabs-head,
.token-tabs {
  background: rgba(43, 46, 53, 0.8);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(2px);
  border-radius: 36px;
}

.sort-col {
  z-index: 99;
  padding-right: 60px !important;
}

.sort-col button {
  font-size: 16px !important;
  line-height: 24px !important;
  padding: 8px 26px !important;
}

.sort-by {
  font-family: 'Poppins-Medium';
  font-size: 20px;
  line-height: 30px;
  color: var(--white);
  padding-right: 10px;
}

.collapse-btn {
  transition: all 5.5s;
}

.sort-collapse-div {
  background: #34373E;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 15px 25px;
  z-index: 999999 !important;
  top: 200%;
  left: 47%;
  text-align: center;
  left: 0px;
  width: max-content !important;
  transition: all 5.5s;
}

.sort-collapse-div p {
  font-family: 'Poppins-Medium';
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}

.sort-collapse-div p:hover {
  color: #8774FF !important;
}

.tabs-head li,
.tabs-head .nav-item {
  width: 50%;
}

.tabs-head li button,
.tab-title {
  padding: 16px 0px !important;
  font-family: 'Poppins-Medium';
  font-size: 24px !important;
  line-height: 36px !important;
  color: var(--white) !important;
  text-align: center;
  width: 100%;
}

.seach-bar {
  background: rgba(43, 46, 53, 0.8) !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24) !important;
  backdrop-filter: blur(2px) !important;
  border-radius: 36px !important;
  height: 67px !important;
  padding: 18px 38px !important;
  border: unset !important;
  font-family: 'Poppins-Medium';
  font-size: 20px;
  line-height: 30px;
  color: var(--white) !important;
  /* width: 100% !important; */

}

.nav-tabs .nav-link.active,
.tab-title.active {
  background: linear-gradient(92.4deg, #7D8EE8 0.57%, #A568FF 97.74%) !important;
  border-radius: 43px !important;
  width: 100%;
  border: unset !important;
  color: var(--white) !important;
  font-family: 'Poppins-Bold';
}

.purple-gradient-img {
  background: linear-gradient(92.4deg, #909FF1 0.57%, #6674F0 97.74%);
  border-radius: 26px;
}

.purple-green-gradient-img {
  background: var(--purple-green-gradient-background);
  border-radius: 26px;
}

.yellow-gradient-img {
  background: var(--yellow-gradient-background);
  border-radius: 26px;
}

.nft-last-gradient-img {
  background: var(--nft-last-gradeint-background);
  border-radius: 26px;
}

.lavendar-gradient-img {
  background: var(--lavendar-background);
  border-radius: 26px;
}

.nft-button-area {
  text-align: center;
}

.nft-card {
  background: var(--box-backrond-2);
  padding: 18px;
  border-radius: 26px;
}

.marketplace-first-tab-content .nft-card:hover {
  /* margin-top: 15px; */
  cursor: pointer;
  transform: translateY(10px);

}

.nft-tab-content .nft-card {
  transition: all 0.5s;
}

.nft-title {
  font-family: 'Poppins-Bold';
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;

  margin-bottom: 0px !important;
}

.tooltip {
  opacity: 1 !important;
}

div#button-tooltip-2 {
  left: 8% !important;
  --bs-tooltip-max-width: max-content !important;
}

.nft-tooltip .tooltip-inner {
  font-family: 'Poppins-Medium';
  font-size: 16px;
  line-height: 24px;
  padding: 10px 18px !important;
  background: #2B2E35 !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 16px 16px 16px 4px !important;
  /* display: inline-block  !important; */
}

.nft-tooltip .tooltip-arrow {
  display: none !important;
}

.nft-text,
.nft-level,
.font-14-500 {
  font-family: 'Poppins-Medium';
  font-size: 14px;
  line-height: 21px;
}

.nft-level {
  font-size: 13px;
}

.nft-tab-content .purple-btn {
  color: var(--white);
  font-size: 16px !important;
  line-height: 24px !important;
  padding: 8px 24px !important;
}

.green-btn {
  background: var(--green-btn-background) !important;
  box-shadow: var(--green-btn-box-shadow) !important;
  border-radius: 31.5px !important;
  padding: 8px 24px !important;
  border: unset !important;
  font-family: 'Poppins-Bold' !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: var(--white) !important;
}

.grey-btn {
  background: var(--grey-btn-background) !important;
  box-shadow: var(--grey-btn-box-shadow) !important;
  border-radius: 31.5px !important;
  padding: 8px 24px !important;
  border: unset !important;
  font-family: 'Poppins-Bold' !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: var(--white) !important;
}

/* end my wallet */
/* mobile navigation */
.mobile-nav {
  display: none;
}

.nav-modal .modal-content {
  background-color: unset !important;
  padding-bottom: 140px;
  border: unset;
}

.nav-modal span {
  color: var(--white);
}

.mobile-toggle-btn,
.mobile-close-btn {
  background: rgba(43, 46, 53, 0.8) !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24) !important;
  border-radius: 36px !important;
  padding: 11px 21px !important;
  border: unset !important;
}

.modal-backdrop.show {
  z-index: 999;
  background: rgba(28, 31, 35, 0.8);
  backdrop-filter: blur(2px);
  opacity: 1 !important;
}

.nav-modal {
  top: 15% !important;
}

.nav-modal .modal-body {

  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}

.nav-modal .modal-dialog {
  max-width: 279px !important;
  margin: auto !important;
}

.nav-modal .nav-link {
  padding: 15px 0px 15px 32px;
}

.btn-close {

  background-image: url(./images/close-icon.png) !important;
  background-size: cover !important;
  background-size: 20px 20px !important;
  background-repeat: no-repeat !important;
  width: 20px;
  height: 20px;
  opacity: 1 !important;
}

.nav-modal {
  overflow: visible;
}

.modal-open .mobile-toggle-btn {
  display: none;
}

.mobile-nav-token {
  position: absolute;
  top: 0px;
  z-index: -1;
  width: 22%;
  left: 10%;
}

.mobile-nav-axe {
  position: absolute;
  top: 59%;
  right: -15px;
  z-index: -1;
}

.mobile-logo {
  width: 143px;
}

.modal-open .mobile-logo {
  width: 163px;
}

/* end mobile navigation */

/* marketplace */
/* .marketplace {
  background-image: url(./images/marketplace-bg.png);
  background-position: top center;

} */
.marketplace .main-inner {
  background-image: url(./images/marketplace-bg-2.png);
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 100vh;
  height: 100%;
  background-position: top center;
}

.market-ower p,
.market-listed p,
.market-mobile-date {
  font-family: 'Poppins-Medium';
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0px;
}

.market-price,
.market-mobile-price {
  font-family: 'Poppins-Bold';
  font-size: 18px;
  line-height: 27px;
}

.text-lavendar {
  color: var(--text-lavendar);
}

/* end marketplace */
/* pagination */
.pagination .page-item .page-link {
  background: #2B2E35 !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24) !important;
  backdrop-filter: blur(2px) I !important;
  border-radius: 10px !important;
  border: unset !important;
  font-family: 'Poppins-Bold';
  font-size: 20px;
  line-height: 30px;
  padding: 9px 18px;
  color: var(--white);
}

.pagination ul {
  gap: 13px;
}

.pagination li.page-item.active .page-link {
  background: var(--purple) !important;
}

/* end pagination */

/* recent sales */
.recent-sales-main {
  margin-top: 33px;
  overflow: hidden;
}

/* .recent-carousel .owl-item {
  transition: opacity .75s linear;
  opacity: 0 !important;
}
.recent-carousel .owl-item.active {
  opacity: 1 !important;
} */
.recent-carousel .owl-stage-outer {
  overflow: visible !important;
  -webkit-transform: unset !important;
}

.recent-sales-date {
  font-family: 'Poppins-Medium';
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  padding: 4px 12px;
  background: #393D45;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(2px);
  border-radius: 8px;
  position: absolute;
  top: -8px;
  z-index: 1;
}

.recent-sales-main .owl-carousel .owl-nav button.owl-prev,
.recent-sales-main .owl-carousel .owl-nav button.owl-next {
  color: var(--white) !important;
  background: rgba(57, 61, 69, 0.6);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(2px);
  padding: 16px 20px !important;
  border-radius: 16px;
  width: 52px;
  height: 52px;
}

.recent-sales-main .owl-carousel .owl-nav button.owl-next span,
.recent-sales-main .owl-carousel .owl-nav button.owl-prev span {
  visibility: hidden;
}

.recent-sales-main .owl-carousel .owl-nav button.owl-next span:before {
  content: "";
  background-image: url(./images/right-angle.png);
  width: 30px;
  height: 30px;
  position: absolute;
  background-repeat: no-repeat;
  visibility: visible;
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;
}

.recent-sales-main .owl-carousel .owl-nav button.owl-prev span:before {
  content: "";
  background-image: url(./images/left-angle.png);
  width: 30px;
  height: 30px;
  position: absolute;
  background-repeat: no-repeat;
  visibility: visible;
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;
}

button.owl-next {
  position: absolute;
  right: -24px;
  top: 36%;
}

button.owl-prev {
  position: absolute;
  left: -24px;
  top: 36%;
}

/* end recent sales */
/* filter */
.all-item.active,
.all-rarity.active,
.all-rarity.active {
  background: var(--purple-gradient-filter-background) !important;
  box-shadow: var(var(--purple-gradient-filter-box-shadow)) !important;
  backdrop-filter: blur(2px);
}

.character:hover,
.all-item:hover,
.all-rarity:hover {
  color: var(--purple) !important;
}

.boxes:hover {
  color: var(--light-yellow) !important;
}

.common:hover {
  color: var(--purple2) !important;
}

.rare:hover {
  color: var(--light-yellow) !important;
}

.epic:hover {
  background: linear-gradient(134.68deg, #35C1D4 6.77%, #8774FF 74.86%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  text-fill-color: transparent !important;

}

.character.active,
.character.active:hover {
  background: linear-gradient(108.44deg, #B164FF 4.04%, #8774FF 83.09%) !important;
  box-shadow: var(var(--purple-gradient2-filter-box-shadow)) !important;
  backdrop-filter: blur(2px);
}

/* button#\32.active:hover{
  color: #fff !important;
} */
.boxes.active,
.rare.active,
.boxes.active:hover,
.rare.active:hover {
  background: linear-gradient(117.26deg, #FFD600 13.7%, #FFA800 67%) !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24) !important;
  backdrop-filter: blur(2px) !important;
}

.common.active,
.common.active:hover {
  background: #7D8EE8 !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24) !important;
}

.epic.active,
.epic.active:hover {
  background: linear-gradient(108.44deg, #35D4C0 4.04%, #8774FF 83.09%) !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24) !important;
}

.filter-item .filter-title {
  font-family: 'Poppins-bold';
  font-size: 24px;
  line-height: 36px;
  color: var(--white);
}

.filter-item {
  margin-top: 44px !important;
}

.filter-item:first-child {
  /* background: red; */
  margin-top: 0px !important;
}

.filter-btn button.active:hover {
  color: var(--white) !important;
  -webkit-text-fill-color: unset !important
}

.filter-btn button {
  font-family: 'Poppins-Medium';
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: var(--white);
  padding: 10px 30px;
  background: #2B2E35 !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24) !important;
  backdrop-filter: blur(2px) !important;
  border-radius: 36px !important;
  border: unset !important;
}

.filter-btn-col .filter-btn {
  font-size: 16px !important;
  line-height: 24px !important;
  padding: 8px 35px !important;
  width: 170px;
}

.filter-btn button:hover {
  background-color: #393D45 !important;
}

.progress {
  position: relative;
  overflow: visible !important;
  height: 4px !important;
}

.progress-number {
  width: 100%;
  /* position: absolute; */
  top: 100%;
  display: flex;
  padding-top: 10px;
}

.progress-bar:after,
.progress-bar:before {
  background-color: var(--purple);
  border-radius: 50%;
  content: " " !important;
  height: 16px;
  position: absolute;
  width: 16px;
}

.progress-bar {
  background-color: var(--purple) !important;
}

.progress-bar:after {
  right: 0px;
}

.progress-number p {
  font-family: 'Poppins-Bold';
  font-size: 20px;
  line-height: 30px;
  color: var(--white);
}

.filter-price-main {
  display: flex;
  gap: 44px;
}

.price-filter-form {
  display: flex;
  gap: 38px;
}

.price-filetr-input,
.input {
  background: #2B2E35 !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24) !important;
  backdrop-filter: blur(2px) !important;
  border-radius: 8px !important;
  font-family: 'Poppins-Medium';
  font-size: 20px !important;
  line-height: 30px !important;
  text-align: center;
  color: var(--text-grey) !important;
  border: unset !important;
  height: 50px;
}

.show-filter-box {
  background: rgba(43, 46, 53, 0.6);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(2px);
  padding: 31px 60px !important;
  margin-top: -36px;
  border-radius: 30px;
}

.filter-btn-col {
  gap: 20px;
  display: flex;
  justify-content: end;
}

.filter-btn {
  gap: 26px;
  display: flex;
}

.white-bordered-btn {
  font-family: 'Poppins-Bold' !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: var(--white-btn-color) !important;
  padding: 10px 47px !important;
  border-radius: 31.5px !important;
  border: 2px solid var(--white-btn-color) !important;
  background: transparent !important;
}

.filter .main-heading {
  font-size: 50px;
  line-height: 57px;
}

.filter-btn-col {
  z-index: 999999;
}

/* end filter */
/* item modal */
.item-modal .modal-dialog {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  text-align: center;
  width: 1073px !important;
  max-width: 1073px !important;

}

.item-modal .modal-content {

  background: #2B2E35 !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24) !important;

  border-radius: 36px !important;
  border: unset !important;
}

.item-modal-body {
  padding: 74px !important;
}

.item-modal-title {
  font-family: 'Poppins-Bold';
  font-size: 32px;
  line-height: 48px;
  text-transform: uppercase;
}

.item-modal-text,
.item-modal-level {
  font-family: 'Poppins-Medium';
  font-size: 20px;
  line-height: 30px;
  color: var(--white)
}

.item-modal-price {
  font-family: 'Poppins-Bold';
  font-size: 32px;
  line-height: 48px;
}

.item-modal-discount {
  font-family: 'Poppins-Bold';
  font-size: 18px;
  line-height: 27px;
  color: var(--white);
}

.item-modal .btn-close {
  position: absolute;
  right: 4%;
  top: 7%;
  z-index: 1;
}

.item-modal-inner-box-title {
  font-family: 'Poppins-Medium';
  font-size: 16px;
  line-height: 24px;
  color: var(--text-grey);
}

.item-modal-inner-box-text {
  font-family: 'Poppins-Medium';
  font-size: 18px;
  line-height: 27px;
  color: var(--white);
  margin-top: 5px;
}

.item-modal-inner-box {
  background: rgba(52, 55, 62, 0.6);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  border-radius: 26px;
  padding: 15px 35px;
  margin-top: 36px;
}



.item-modal-left-img {
  height: 100% !important;
  object-fit: cover;
}

.item-i-btn {
  text-align: center;
  padding: unset;
  width: 56px;
  height: 39px;
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  background: var(--yellow-background);
  box-shadow: var(--yellow-box-shadow);
  border-radius: 31.5px;
  margin-left: 21px;
}

.yellow-btn-two {
  font-family: 'Poppins-Bold' !important;
  border: unset !important;
  background: var(--yellow-background) !important;
  box-shadow: var(--yellow-box-shadow) !important;
  border-radius: 31.5px !important;
  padding: 8px 44px !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.sell-modal-input {
  color: rgba(164, 169, 199, 0.8);
  font-family: 'Poppins-Medium';
  font-size: 20px;
  line-height: 30px;
  background: rgba(52, 55, 62, 0.6);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  border-radius: 16px;
  border: unset;
  padding: 10px 23px;
  width: 176px;
  height: 50px;
  display: block;
  /*text-align: end; */
  margin-left: auto;
  margin-bottom: 26px;
}

input.sell-modal-input::placeholder {
  color: rgba(164, 169, 199, 0.8);
}

.sell-modal-inner-box {
  height: 168px;
}

.sell-modal-body {
  background-image: url(./images/zig-two.png);
  background-repeat: no-repeat;

  background-position-y: 78px;
  background-position-x: 75%;

}

/* end item modal*/
/* nft renting */
.renting .main-inner {
  background-image: url(./images/renting-bg-2.png);
  background-size: 87%;
  background-repeat: no-repeat;
  background-position: top;
  background-position-x: 133px;
}

.renting-rigth-main {
  background: var(--purple-green-gradient2-background);
  border-radius: 36px;
  padding: 34px 0px;

}

.dark-yellow-btn {
  padding: 12px 44px !important;
  font-family: 'Poppins-Bold' !important;
  font-size: 24px !important;
  line-height: 36px !important;
  color: var(--white) !important;
  border: unset !important;
  background: var(--darkyellow-button-background);
  box-shadow: var(--darkyellow-button-box-shadow);
  border-radius: 31.5px !important;
  position: relative;
  z-index: 99999;
}

.renting-top-icon-one {
  top: 20%;
  left: 1%;
}

.renting-top-icon-two {
  bottom: 10%;
  right: 1%;
  transform: rotate(-26deg);
}

.font-20-500 {
  font-family: 'Poppins-Medium';
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: var(--white)
}

.renting-text-area {
  margin-top: 48px;
}

.renting-text-area .box {}

.renting-text-area .box {
  padding: 40px 50px;
  margin-top: -34px;
}

/* end nft renting */
/* play */
.play .form-input-group:not(:first-child) {
  margin-top: 34px !important;
}

.play .form-input-group.valiadtion-error {
  margin-top: 0px !important;
}

.play .error {
  font-family: 'Poppins-Medium';
  font-size: 14px;
  line-height: 21px;
  padding-top: 4px;
  color: #FF414C;
  padding-left: 15px;
}

.play-starter-pack {
  background: var(--purple-green-gradient2-background);
  border-radius: 36px;
}

.input.inputerror {
  border: 2px solid #FF414C !important;
}

.play-starter-pack-inner {
  background-image: url(./images/paly-starter-pack-img.png);
  background-size: contain;
  background-repeat: no-repeat;
  padding: 43px 53px 43px 88px;
  position: relative;
  z-index: 99999;
}

/* .play-starter-second-box {
  background-image: url(./images/hh.png);
  background-size: contain;
  background-repeat: no-repeat;
} */

.play-starter-left-col h1 {
  font-family: 'Titan-One';
  font-size: 70px;
  line-height: 80px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  background: linear-gradient(90deg, #E5D3FF 0%, #C8FFF8 97.32%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.play-starter-left-col span {
  font-family: 'Poppins-bold';
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  background: linear-gradient(90deg, #E5D3FF 0%, #C8FFF8 97.32%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.starter-play-card {
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.6);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(2px);
  border-radius: 24px;
  padding-top: 7px;
}

.starter-play-card-inner {
  padding: 0px 7px 7px 21px;
}

.starter-paly-card-title,
.starter-paly-card-bottom-text {
  font-family: 'Titan-One';
  font-size: 30px;
  line-height: 34px;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--white);
  text-shadow: 1px 2px 0px #3C8EC1;
}

.starter-paly-card-bottom-text {
  font-size: 40px;
  line-height: 46px;
}

.profile-cal-title {
  font-family: 'Titan-One';
  font-size: 50px;
  line-height: 57px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.profile-calculator {
  margin-top: 50px;
}

.profile-calculator .box {
  backdrop-filter: unset !important
}

.profile-cal-box {
  margin-top: -30px;
}

.profile-cal-left-col {
  background-image: url(./images/profile-cal-bg.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-position-y: 72%;
}

.profile-cal-left-col {
  font-size: 24px;
  line-height: 36px;
}

.profile-cal-btn-main {
  display: block;

  justify-content: center;
}

.profile-cal-btn-main button {
  background: #2B2E35 !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24) !important;
  backdrop-filter: blur(2px) !important;
  border-radius: 36px !important;
  border: unset !important;
  font-family: 'Poppins-Medium';
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: var(--white);
  padding: 10px 24px !important;
  margin-left: 13px;
  margin-right: 13px;

}

.profile-cal-btn-main button:hover {
  color: var(--purple);
  background-color: #393D45 !important;
}

.profile-cal-btn-main button.profile-btn-active {
  background: linear-gradient(95.34deg, #7D8EE8 19.1%, #A568FF 91.28%) !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24) !important;
  backdrop-filter: blur(2px) !important;
}

.profile-cal-btn-main button.profile-btn-active:hover {
  color: var(--white);
}

.font-20-700 {
  font-family: 'Poppins-bold';
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: var(--white)
}

.font-16-500 {
  font-family: 'Poppins-Medium';
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--white)
}

.profile-cal-price {
  font-family: 'Titan-One';
  font-size: 40px;
  line-height: 46px;
  text-align: center;
}

.font-32-700 {
  font-family: 'Poppins-Bold';
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--white)
}

.profile-cal-img {
  margin-top: 100px;
}

.profile-cal-zig {
  position: absolute;
  right: -73px;
  top: -15px;
}

.label {
  font-family: 'Poppins-Bold';
  font-size: 24px;
  line-height: 36px;
  color: var(--white);
}

.form-input-group {
  display: grid;
  grid-gap: 60px;
  grid-template-columns: 20% 70%;
}

.create-account-inner,
.change-password-inner {
  max-width: 658px;
  margin: 0 auto;
}

.create-account {
  background-image: url(./images/create-account-bg.png);
  background-size: cover;
  background-position: center;
  background-position: center center;
  background-position-y: 64%;
  margin-top: 80px;
}

/* .change-password {
  background-image: url(./images/change-password-bg.png);
  background-size: cover;
  background-position: center;
} */

.heading-font-60 {
  font-size: 60px;
  line-height: 69px;
}

form.craete-account-form,
form.change-password-form {
  margin-top: -30px;
  padding: 43px 62px 41px 42px !important;
}

.change-password {
  margin-top: 100px;
}

.change-password input,
.create-account input {
  border-radius: 16px !important;
  text-align: left !important;
  padding: 10px 29px;
}

/* .play {
  background-image: url(./images/play-bg.png);
} */
.play .main-inner {
  background-image: url(./images/play-bg-2.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-position-y: 38%;
  background-position-x: 192px;
  overflow-x: hidden;
}

.change-pass-hamer {
  top: 20px;
  right: -71px;
}

.change-pass-axe {
  left: -72px;
  bottom: 54px;
  transform: rotate(-73deg);
  width: 20%;
  z-index: 999;
}

.password-eye-icon {
  right: 15px;
  top: 49%;
  cursor: pointer;
  transform: translate(-50%, -50%);
}

/* end play */
/* my token */
.token-filed-right-staic-text {
  top: 50%;
  transform: translate(-50%, -50%);
  right: -7px;
  font-family: 'Poppins-Medium';
  color: var(--text-grey) !important;
  width: 52px;
  text-align: center;
}

.token-tabs .nav-item {
  width: 33.33%;
}

.token-grey-btn {
  background: var(--grey-btn-background) !important;
  box-shadow: var(--grey-btn-box-shadow) !important;
  font-family: 'Poppins-Bold' !important;
  font-size: 24px !important;
  line-height: 36px !important;
  text-align: center !important;
  color: var(--white) !important;
  padding: 12px 63px !important;
  border-radius: 31.5px !important;
  border: unset !important;
}

.token .main-inner {
  background-image: url(./images/token-bg-2.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 34px;
}

.token-tab-content-box {
  background-image: url(./images/token-icon.png);
  background-repeat: no-repeat;
  background-position-y: 63%;
  background-size: 150px;
  background-position-x: 95%;
  margin-top: -30px;
}

.vestiing-bg {
  background-image: url(./images/vesting-bg.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.font-60 {
  font-size: 60px;
  line-height: 69px;
}

.vesting-tab-content {
  max-width: 540px;
  margin: 0 auto !important;
}

.vesting-tab-content-box {
  background-image: unset !important;
}

/* end my token */
/* staking */
.staking .main-inner {
  background-image: url(./images/staking-bg-2.png);

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  background-position-y: 37px;
  background-position-x: 90px;
}

.font-32 {
  font-size: 32px;
  line-height: 37px;
}

.staking-card-one {
  background-image: url(./images/staking-one.png);
  background-repeat: no-repeat;
  padding-top: 137px;
  background-position: center;
  background-position-y: 0% !important;
}

.staking-card-two {
  background-image: url(./images/staking-two.png);
  background-repeat: no-repeat;
  padding-top: 137px;
  background-position: center;
  background-position-y: 0% !important;
}

.staking-card-three {
  background-image: url(./images/staking-three.png);
  background-repeat: no-repeat;
  padding-top: 137px;
  background-position: center;
  background-position-y: 0% !important;
}

.staking-card-one button,
.staking-card-two button,
.staking-card-three button {
  padding: 10px 24px 8px 24px !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.staking-table {
  margin-top: 100px;
}

.staking-table-bottom {
  margin-top: 22px;
}


.heading p {
  font-family: 'Poppins-Bold';
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  padding-top: 14px !important;
  color: var(--white);
  margin-bottom: 0px;
}

.staking-table th {
  font-family: 'Poppins-Bold';
  font-size: 20px;
  line-height: 30px;
  text-align: center;

  color: var(--white);
  margin-bottom: 0px;
}

.stak-transtion-col {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.stak-transtion-col .green-btn {
  padding: 8px 32px !important;
}

.font-medium-col p {
  font-family: 'Poppins-Medium' !important;
}

.staking-table thead {
  /* line-height: 22px;
  content: ".";
  color: transparent;
  display: block; */
  /* border-bottom: 22px solid transparent !important; */
}

.d-flex.heading.box>div {
  width: -webkit-fill-available;
  text-align: center !important;
}

.staking-table .table>tbody td {
  font-family: 'Poppins-Medium';
  font-size: 20px;
  line-height: 30px;
  color: var(--white);
  text-align: center;
}

.d-flex.heading.box {
  padding-left: 34px;
}

.staking-table .box {
  box-shadow: unset !important;
}

.staking-table thead,
.staking-tabletbody,
.staking-table tfoot,
.staking-table tr,
.staking-table td,
.staking-table th {
  border: unset;
}

.staking-table th:not(:last-child),
.staking-table td:not(:last-child),
.heading>div:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.staking-text-box {
  background: #2B2E35;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  border-radius: 16px !important;
  margin-top: 10px;
  font-family: 'Poppins-Medium';
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: var(--white);
  height: 50px;
}

.staking-text-box:focus::placeholder {
  color: transparent;
}

.staking-text-box:focus {
  outline: none;
}

.staking-text-box::placeholder {
  color: var(--white);
}

.locked {
  color: #FF4163 !important;
  font-family: 'Poppins-Bold' !important;
  position: relative;
}

.claimed {
  color: #A4A9C7 !important;
  font-family: 'Poppins-Bold' !important;
  position: relative;
}

.locked:before {
  content: "";
  width: 4px;
  height: 16px;
  background: #FF4163;
  position: absolute;
  left: 23px;
  z-index: 999999 !important;
  top: 8px;
}

.claimed:before {
  content: "";
  width: 4px;
  height: 16px;
  background: #A4A9C7;
  position: absolute;
  left: 23px;
  z-index: 999999 !important;
  top: 8px;
}

.stak-transtion-col {
  white-space: nowrap;
  padding: 0px 30px;
}

.transation-yellow-line:before {
  content: "";
  background: var(--yellow-background);
  width: 4px;
  height: 20px;
  position: absolute;
}

.transation-green-line:before {
  content: "";
  background: var(--green-btn-background);
  width: 4px;
  height: 20px;
  position: absolute;
}

.transation-purple-line:before {
  content: "";
  background: var(--purple);
  width: 4px;
  height: 20px;
  position: absolute;
}

/* .transation-yellow-line-box::before{
  content: "";
  position: absolute;
  background-attachment: red;
  width:6px;
  height: max-content;


} */
.transation-yellow-line-box {

  background-image: url(./images/yellow.png);
  background-size: contain;
  background-repeat: no-repeat;

}

.transation-green-line-box {
  background-image: url(./images/green.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.transation-purple-line-box {
  background-image: url(./images/purple.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.transation-green-line-box .locked:before,
.transation-purple-line-box .claimed:before {
  display: none;
}

/* .staking .main-inner{
  background-image: url(./images/staking-bg.png);
} */

/* end staking */
@media(min-width:1300px) {
  .main-heading-space-left {
    padding-left: 32px;
  }

}

@media(min-width:1450px) {
  .navigation-col {
    width: 259px;
  }

  .home-hamer-img-one {
    top: 9%;
  }

  .play .main-inner {

    background-position-y: 172px;
    background-position-x: 92px;
  }

  .staking .main-inner {

    background-position-x: -22px;
  }

  .token .main-inner {

    background-position-x: 3px;
  }
}

@media(max-width:769px) {}

@media(max-width:1400px) {

  .staking-table .table>tbody td {
    text-align: left;
    padding-left: 15px !important;
    padding-right: 15px !important;
    font-size: 19px;
    line-height: 28px;
  }

  .purple-gradient-box {

    padding: 36px 30px 55px 70px;
  }

  .staking-table th {
    font-size: 19px;
    line-height: 28px;
  }

  .content-top-bar p {
    font-size: 15px;
  }

  .box.top-bar-box.px-5.px-xl-5.px-lg-3.px-md-3.py-3 {
    padding: 14px 18px !important;
  }

  .switch-main-box {
    padding-top: 100px;
  }

  /*home */
  .token-text-white,
  .nft-text-white {
    gap: 50px;
  }

  .nft-text-white {
    width: 54%;
  }

  .token-box {
    background-size: 23%;
  }

  /* end home */
  /* nft wallet */
  .nft-button-area.px-3.py-2 {
    padding: 0px !important;
  }

  .nft-button-area button {
    font-size: 14px;
  }

  /* end nft wallet */
  /* token */
  .main-heading {
    font-size: 50px;
  }

  /* end token */
  /* staking */
  .staking-col .font-32,
  .staking-col .font-60 {
    font-size: 40px;
    line-height: 47px;
  }

  .staking-col .font-30 {
    font-size: 27px !important;
    line-height: 35px !important;
  }

  /* end staking */
}

@media(max-width:1199px) {

  img.w-100.play-right-img1,
  img.w-100.play-right-img2 {

    margin-top: 20px !important;
  }

  .play-right-col {
    display: grid;
    gap: 11px;
    grid-template-columns: 1fr 1fr;
  }
}

@media(max-width:1280px) {

  /* home */
  .token-text-white,
  .nft-text-white {
    font-size: 17px;
  }

  .main-heading {
    font-size: 50px;
    line-height: 60px;
  }

  .play-game-large-heading {
    font-size: 80px;
  }

  /* end home */
}

@media(max-width:1100px) {
  .nav-toggle-img {
    display: none;
  }

  .navigation-col {
    height: unset;
    right: unset;
    width: 20%;
  }

  img.play-starter-second-box-img1 {
    position: absolute;
    top: -49px;
    width: 67.5%;
    left: -175px;
  }

  img.play-starter-second-box-img2 {
    position: absolute;
    right: -42px;
    top: -72px;
    width: 48%;
  }

  .content-col {
    width: 80%;
    margin-left: 20%;
  }

  /* .content-col{
    margin-left:26%;
  } */
  .mobile-display {
    display: block;
  }

  .desktop-display {
    display: none;
  }

  .swicth-network-main {
    background-image: url('./images/switch-network-bg-mobile.png');

  }

  /* item modal */
  .item-modal {
    display: -webkit-box !important;
    -webkit-box-pack: center !important;
    -webkit-box-align: center !important;
  }

  .sell-modal-input {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 22px;
    margin-top: 27px;
  }

  .sell-modal-inner-box {
    height: 118px;
  }

  .item-modal-inner-box-title {
    padding-top: 12px;
  }

  .sell-modal-body {
    background-position-y: 88%;
    background-position-x: 101%;
    background-size: 44%;
  }

  /* end item modal */
  /* home */
  /* .navigation-col {
    width: 26% !important;
  }

  .content-col {
    width: 74% !important;
  } */

  .content-top-bar p {
    font-size: 16px;
  }

  .purple-gradient-box {
    padding: 30px;
  }


  .home-hamer-icon-one {
    right: 0px;
  }

  .home-hamer-img-one {
    top: 14%;
    right: 0;
    width: 30%;
  }

  /* end home */
  /* marketplace */
  .show-filter-box {
    padding: 32px 18px !important;
  }

  .filter-btn {
    display: block;
  }

  .filter-btn button {
    font-size: 16px;
    margin-right: 14px;
    margin-top: 18px;
  }

  .progress {
    margin-top: 30px;
  }

  .filter-price-main {
    gap: 20px;
    margin-top: 30px;
  }

  .price-filter-form {
    gap: 10px;
  }

  .item-modal .modal-dialog {
    max-width: -webkit-fill-available !important;
    width: -webkit-fill-available !important;
    transform: unset !important;
    position: unset !important;
    margin-left: 30px;
    margin-right: 30px;

  }

  .item-modal-body {
    padding: 40px !important;
    padding-top: 70px !important;
  }

  .item-modal .btn-close {
    top: 3%
  }

  /* .item-modal-right-col {
    margin-top: 30px;
  } */

  .tab-title {
    font-size: 20px !important;
    line-height: 27px !important;
  }

  /* end marketplace */
  /* play */
  .play-starter-pack-inner {
    padding: 30px;
  }

  .starter-play-card {
    margin-top: 30px;
  }

  .play-starter-second-box {
    background-position: center;
  }

  .profile-cal-zig {
    right: 0px;
  }

  .filter-btn {
    font-size: 18px !important;
  }

  /* end play */

}

@media(max-width:1050px) {

  /* navigation */
  .mobile-nav {
    display: block;
  }

  .mobile-nav {
    padding-right: 18px;
    padding-left: 18px;
  }

  .desktop-nav {
    display: none;
  }

  .navigation-col,
  .content-col {
    width: 100% !important;
  }

  .content-col {
    margin-left: 0%;
    padding-top: 95px !important;
  }
}

@media(max-width:900px) {
  .navigation-col {
    z-index: 999999999;
    right: 0px;
    background: linear-gradient(180deg, #2B2E35 0%, rgba(43, 46, 53, 0) 100%);
  }

  .mobile-nav {
    padding-right: 18px;
    padding-left: 18px;
  }

  .content-col {
    margin-left: 0%;
    padding-top: 95px !important;
  }


  /* .navigation-col{
  z-index: 999999;
} */
  /* end navigation */
  /* home */
  .navigation-col,
  .content-col {
    width: 100% !important;
  }

  .content-top-bar {
    display: none;
  }

  /* end home */

  /* marketplace */
  .filter .main-heading {
    font-size: 40px;
    line-height: 50px;
  }

  /* end marketplace */

}

@media(max-width:600px) {
  .top-bar-box {
    box-shadow: unset !important;
  }

  .item-modal .modal-content {
    border-radius: 24px !important;
  }

  .marketplace-first-tab-content .nft-card:hover {
    transform: unset !important;
  }

  .box {
    border-radius: 24px;
  }

  .purple-btn {
    font-size: 18px !important;
    line-height: 27px !important;
  }

  .sidebar-social-div {
    gap: unset !important;
  }

  .sidebar-social-div img {
    margin-right: 45px;
  }

  .mobile-nav-bar .nav-link {
    padding: 13px 0px 13px 45px !important;
  }

  .nav-modal .nav-link {
    padding: 15px 0px 15px 45px;
  }

  .nav-modal .nav-link img.pe-3 {
    padding-right: 20px !important;
  }

  .connect-box {
    margin-top: -20px;
  }

  .switch-main-box {
    padding-top: 0;
  }

  .margin-top-box.recent-carousel {
    margin-top: -20px;
  }

  .swicth-network-main .box {
    width: auto;
    position: unset;
    transform: unset;
    margin-left: 30px;
    margin-right: 30px;
    /* margin-top: 170px; */
  }

  .swicth-network-main .blue-btn {
    width: 100%;
  }

  .switch-main-box {
    width: 100%;
  }

  .logo {
    width: 180px;
  }

  /* .switch-zig-one,
  .switch-zig-two {
    width: 200px;
  } */

  .swicth-network-main .box {
    padding: 30px;
  }

  .font-24-700 {
    font-size: 20px;
    line-height: 30px;
  }

  .blue-btn {
    font-size: 18px;
    padding: 12px 25px;
    line-height: 27px !important;
  }

  .switch-zig-one {
    top: 10px;
    left: 12px;
    width: 51px;
  }

  body.main.play.modal-open .play-starter-pack-inner {
    z-index: unset;
  }

  .modal-backdrop.show {
    z-index: 999999;
  }

  .switch-zig-two {
    top: 29%;
    right: 0%;
    width: 80px !important;

  }

  .switch-main-box {
    top: 58%;
  }

  /* connect */
  .font-400-70 {
    font-size: 40px;
  }

  .connect-text {
    font-size: 18px;

  }

  .font-16-500 {
    font-size: 14px;
  }

  .font-16-700 {
    font-size: 16px;
    font-family: 'Poppins-Bold';
  }

  img.connect-zig-two {
    right: 0px;
  }

  img.connect-zig-one {
    left: 0px;
  }

  .connect {
    background-image: url(./images/connect-mobile-bg.png);
    background-position-y: 40px;
  }

  .connect-box-main,
  .connect-box,
  .connect-inner-card {
    width: 100%;
  }

  .connect-inner-card {
    padding: 14px 18px;
  }

  .connect-box {
    padding: 34px 21px;
  }

  p.connect-text.text-center {
    text-align: left !important;
  }

  .connect-inner-card img {
    width: 55px;
    height: 30px;
    object-fit: contain;
    margin: auto !important;
  }

  .connect-box-main {
    padding-left: 27px;
    padding-right: 27px;
  }

  img.connect-zig-two {
    bottom: -56px;

  }

  img.connect-zig-one,
  img.connect-zig-two,
  .main.connect .content-col {
    display: none !important;
  }

  /* end connect */
  /* home */
  /* .home-nft-col{
    background-image: url(./images/home-nft-mobile-bg.png);
  }*/
  .home {
    background-image: url(./images/home-mobile-bg.png);
    background-size: 100%;
    background-position-y: 24%;
  }

  img.home-nft-img {
    display: none;
  }

  .home-hamer-img-one {
    top: 10%;
    right: 0px;
    width: 42%;
  }

  .home .purple-gradient-box {
    padding: 23px;
  }

  .home .yellow-btn,
  .home .purple-btn {
    font-size: 18px !important;
    line-height: 27px !important;
    padding: 9px 43px !important;
  }

  /* .home-nft-img-mobile{
    display: block;
    position: absolute;
    top: -200px;
    left: -26px;
  } */
  .home-token-img {
    display: none;
    /* width: 29%;
z-index: -1;
    top: -37px;
    right: -19px; */

  }

  .home .content-col {
    padding-bottom: 60px;
  }

  .token-box,
  .nft-box {
    margin-top: -23px;
  }

  .sort-col .purple-btn {
    font-size: 16px !important;
    padding: 8px 24px !important;
  }

  .green-btn {
    padding: 8px 20px !important;
  }

  .home-hamer-icon-one,
  .home-hamer-icon-two,
  .home-eagle-icon,
  .home-axe-icon {
    display: none;
  }

  .play-game-large-heading {
    font-size: 50px;
    line-height: 57px;
  }

  .play-game-small-heading {
    font-size: 24px;
    line-height: 27px;
  }

  .play-dektop-title {
    display: none !important;
  }

  .play-mobile-title.d-none {
    display: block !important;
  }

  .play-mobile-title h2 {
    font-family: 'Titan-One';
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    background: linear-gradient(90deg, #E4D2FF 0%, #C8FEF8 96.86%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent
  }

  .play-starter-pack-inner {
    background-image: url(./images/paly-starter-pack-img-mobile.png);
  }

  .play-game-sub-text {
    font-size: 14px;
    line-height: 21px;
  }

  .change-pass-hamer,
  .change-pass-axe {
    display: none;
  }

  .password-eye-icon {
    top: 47px;
    transform: unset;
  }

  form.craete-account-form,
  form.change-password-form {
    margin-top: -23px;
  }

  .play .content-col {
    padding-bottom: 120px;
  }

  .main-heading {
    font-size: 40px;
    line-height: 46px;
  }

  .home .main-heading {
    text-align: center;
  }

  .token-text-white,
  .nft-text-white {
    width: 100%;
  }

  .token-last-text p,
  .nft-last-text p {
    margin-bottom: 0px !important;
    padding-top: 10px;
  }

  .box.px-4.py-5.token-box,
  .box.px-4.py-5.nft-box {
    padding: 35px 21px 22px 21px !important;
  }

  /* end home */
  /* nft wallet */
  .sort-col {
    padding-right: 0px !important;
  }

  .sort-col.text-end {
    text-align: end !important;
    padding-right: 35px !important;
  }

  .sort-collapse-div {
    /* left: unset !important;
    right: 25px !important; */
    padding: 15px 23px !important;
  }

  .tab-title {
    font-size: 18px !important;
  }

  .sort-col {
    text-align: center;
  }

  .nft-inner-box {
    background: rgba(52, 55, 62, 0.8);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
    border-radius: 24px;
    display: flex;
    /* grid-template-columns: 1fr 1fr; */
    /* gap: 13px; */
  }

  .nft-text-area {
    padding-left: 10px !important;
  }

  .nft-card .nft-text-area .yellow-btn img {
    width: 24px !important;
  }

  div#button-tooltip-2 {
    left: 0% !important;
  }

  .pagination ul {
    gap: unset;
  }

  .pagination ul li.page-item {
    margin-right: 10px;
  }

  .nft-inner-box img,
  .nft-inner-box .nft-text-area {
    width: 50% !important;
  }

  .nft-inner-box img {
    height: auto !important;
  }

  .nft-inner-box .nft-text-area img {
    width: 4px !important;
    height: 100% !important;
  }

  .nft-inner-box {
    padding-right: 17px !important;
  }

  .nft-button-area {
    display: flex;
    margin: auto;
    text-align: center;
    justify-content: center;
    margin-top: 1rem !important;
    gap: 0px;
  }

  .nft-card {
    background: unset;
    padding: unset;
    border-radius: unset;
  }

  .nft-title {
    font-size: 16px;
    line-height: 24px
  }

  .nft-tab-content .tab-content {
    padding-top: 35px !important;
  }

  .my-wallet {
    background-image: url(./images/nft-wallet-bg-mobile.png);
  }

  .d-flex.nft-with-exchange {
    display: block !important;
  }

  .nft-with-exchange .flex-grow-1 {
    margin-right: 0px;
    /* width: 47px !important;
    height: 36px !important; */
  }

  .nft-with-exchange .flex-grow-1 .yellow-btn {

    width: 47px !important;
    height: 36px !important;
  }

  .my-wallet .nft-inner-box {
    padding-right: 17px !important;
    height: 143px;
  }

  .nft-inner-box img {
    height: 100%;
    object-fit: cover;
  }

  .filter-btn {
    font-size: 16px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    line-height: 20px !important;
  }

  .filter-item {
    margin-top: 18px !important;
  }

  .my-wallet .mt-5.nft-card-main {
    margin-top: 43px !important;
  }

  .my-wallet .mt-5.nft-card-main:first-child {
    margin-top: 18px !important;
  }

  .my-wallet .margin-top-box {
    margin-top: -24px;
  }

  .my-wallet .nft-tab-content .tab-content {
    padding-top: 20px !important;
  }

  .my-wallet .p-3.p-xl-4.pt-xl-3.nft-tab-content {
    padding-bottom: 33px !important;
  }

  /* end nft wallet */

  /* item modal */
  .item-i-btn {
    width: 40px;
    height: 30px;
    margin-left: 0px;
  }

  .item-modal-title,
  .item-modal-price {
    font-size: 20px;
    line-height: 30px;
  }

  .item-modal-text,
  .item-modal-level,
  .item-modal-discount {
    font-size: 16px;
    line-height: 24px;
  }

  .item-modal-right-col.d-flex {
    display: block !important;
  }

  .left-side.text-start .d-flex {
    display: flex !important;
  }

  .item-modal-body {
    padding: 20px !important;
  }

  .market-first-tab-modal {
    padding-bottom: 28px !important;
  }

  .item-modal .modal-dialog {
    width: 332px;
  }

  .pe-0.sell-modal-left-col {
    padding-right: 10px !important;
  }

  .item-modal .btn-close {
    top: 3%;
    right: 5%;
    width: 1px;
    background-size: contain !important;
  }

  .sell-modal-right-col {
    padding-left: 0px !important;
  }

  .right-side.text-end {
    text-align: left !important;
  }

  .item-modal-inner-box-text {
    word-break: break-all;
    font-size: 16px;
  }

  .item-modal-inner-box {
    padding: 15px 25px;
    border-radius: 20px;
  }

  .sell-modal-body {
    padding: 18px 21px 38px 21px !important;
  }

  .item-modal .modal-content {
    border-radius: 23px;
  }

  /* end item modal */

  /* marketplace */
  .marketplace .nft-inner-box .nft-text-area img {
    height: auto !important;
  }

  .marketplace .marketplace-first-tab-content .nft-level {
    display: none;
  }

  .marketplace .market-mobile-price {
    margin-bottom: 0px !important;
  }

  .marketplace .mt-5.nft-card-main {
    margin-top: 17px !important;
  }

  .marketplace .mt-5.nft-card-main:first-child {
    margin-top: 30px !important;
  }

  .marketplace .marketplace-first-tab-content .nft-inner-box {
    height: 191px;
  }

  .marketplace .nft-text-area {
    position: relative;
  }

  .marketplace .market-mobile-price {
    position: absolute;
    bottom: 0px;
  }

  .marketplace .nft-text-area.pb-3 {
    padding-bottom: 4px !important;
  }

  .marketplace .margin-top-box {
    margin-top: -22px;
  }

  .marketplace-mobile-diplay.d-none {
    display: block !important;
  }

  .sell-modal-right-col {
    padding-top: 10px;
  }

  .marketpalce-desktop-display {
    display: none;
  }

  .recent-sales-date {
    right: 16px;
  }

  .marketplace .marketplace-tab-content .nft-inner-box {
    padding: 17px;
  }

  .pagination.justify-content-end {
    justify-content: center !important;
  }

  .filter .main-heading {
    text-align: center;
  }

  .filter-btn-col {
    justify-content: center;
    padding-top: 10px !important;
  }

  .filter-btn-col .filter-btn {
    width: unset !important;
  }

  .filter-price-main {
    margin-top: 0px;
    padding-top: 70px;
  }

  .horizontal-slider {
    margin-top: 30px;
  }

  .progress {
    margin-top: 20px;
  }

  .filter-btn-col button {
    padding: 12px 25px !important;

  }

  .filter .main-heading {
    margin-top: -50px;
  }

  .filter.mt-4 {
    margin-top: 50px !IMPORTANT;
  }

  /* .filter .box{
    border-radius: 36px !important;
  } */
  .marketplace .filter .box .text-end.my-auto.filter-btn-col.col-xl-6.col-lg-8.col-md-8.col-12 {
    background: rgba(43, 46, 53, 0.6);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    border-radius: 36px !important;
    padding: 23px 0px !important;
    margin-top: -16px !important;
  }

  .marketplace .filter .box {
    background: unset;
    box-shadow: unset;
    -webkit-backdrop-filter: unset;
    backdrop-filter: unset;
  }

  .filter-btn-col .filter-btn {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .box .filter-btn-col .filter-btn {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  #filter-inner .filter-btn button {
    line-height: 24px !important;
  }

  .show-filter-box {
    padding-top: 100px !important;
    margin-top: -82px;
  }

  .filter-price-main {
    display: block;
  }

  .filter-price-main .filter-title {
    margin-bottom: 14px !important;
  }

  .my-collection-tab-content button.purple-btn.w-100 {
    width: 60% !important;
  }

  .marketplace {
    background: url(./images/marketplace-bg-mobile.png);
    background-size: contain;
  }

  .box.margin-top-box.recent-carousel.p-3.p-xl-4.pt-xl-5 {
    padding: 36px 18px !important;
  }

  .recent-carousel .nft-inner-box {
    height: auto !important;
  }

  .marketplace .recent-carousel .market-mobile-price {
    position: unset !important;
  }

  .recent-sales-main h1 {
    padding-left: 28px !important;
  }

  .marketplace .my-collection-tab-content .mt-5.nft-card-main {
    margin-top: 40px !important;
  }

  /* end marketpalce */

  /* nft renting */
  .renting-top-icon-one,
  .renting-top-icon-two {
    display: none;
  }

  .renting-rigth-main.text-center {
    text-align: left !important;
  }

  .renting-rigth-main {
    padding: 23px;
  }

  .dark-yellow-btn {
    padding: 8px 38px !important;
    font-size: 18px;
  }

  .renting-text-area .box {
    padding: 32px;

  }

  .renting-text-area .font-20-500 {
    font-size: 16px;
    line-height: 24px;
  }

  /* end nft renting */
  /* play */
  .play-starter-left-col h1 {
    font-size: 40px;
    line-height: 46px;
  }

  img.play-starter-second-box-img2,
  img.play-starter-second-box-img1 {
    display: none !important;
  }

  .play-starter-left-col {
    text-align: center;
  }

  .profile-cal-left-col {
    height: 399px;
    width: 100%;
    padding-top: 200px;
  }

  .profile-cal-left-col {
    background-image: url(./images/profile-cal-bg-mobile.png);
  }

  .profile-calculator {
    margin-top: -30px;
  }

  .starter-paly-card-title,
  .starter-paly-card-bottom-text {
    font-size: 20px;
    line-height: 23px;
  }

  .play-starter-pack-inner {
    padding: 30px 0px;
  }

  .play-starter-right-col {
    padding-left: 20px;
    padding-right: 20px;
  }

  /* .play-starter-second-box {
    background-image: url(./images/play-secon-box-bg-img-mobile.png);
    background-position: right;
    text-align: left !important;
    padding: 23px !important;
  } */
  .play-starter-second-box-img-mobile.d-none {
    display: block !important;
    position: absolute;
    top: -76px;
    right: -27px;
    width: 75%;
  }

  .play-starter-second-box {
    text-align: left;
  }

  .play-starter-second-box {

    padding: 20px !important;
  }

  .profile-cal-title {

    font-size: 40px;
    line-height: 45px;
  }

  .profile-cal-box {
    margin-top: -23px;
  }

  .box.profile-cal-box.text-center.pt-4.pb-4 p {
    margin-bottom: 0px !important;
  }

  .profile-cal-btn-main.mt-3 {
    margin-top: 0px !important;
  }

  .change-password {
    margin-top: 70px;
  }

  .profile-cal-btn-main button {
    margin-top: 24px;
  }

  .form-input-group {
    display: block;
  }

  .starter-play-card {
    height: 199px;
  }

  .starter-play-card {
    margin-top: 16px;

  }

  .play .dark-yellow-btn {
    font-size: 18px !important;
    line-height: 27px !important;
    padding: 9px 43px !important;
  }

  .play .play-starter-second-box .dark-yellow-btn {

    padding: 9px 39px !important;
  }

  .play-starter-pack {
    border-radius: 24px;
  }

  .starter-paly-card-bottom-text {
    margin-bottom: 0px !important;
    font-size: 24px;
    line-height: 27px;
  }

  .profile-cal-left-col p {
    font-size: 24px !important;
    line-height: 36px !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .play .label {
    font-size: 18px;
    line-height: 27px;
    padding-left: 10px;
  }

  form.craete-account-form,
  form.change-password-form {
    padding: 29px 21px 21px 21px !important;
  }

  .play .form-input-group:not(:first-child) {
    margin-top: 20px !important;
  }

  .play .form-input-group.valiadtion-error {
    margin-top: 0px !important;
  }

  .play .error {
    font-size: 12px;
    line-height: 18px;
    padding-left: 11px;
  }

  .create-account,
  .change-password {
    background-image: unset !important;
  }

  /* end play */
  .renting .dark-yellow-btn,
  .token .yellow-btn,
  .token .purple-btn {

    font-size: 18px !important;
    line-height: 27px !important;
  }

  .token .yellow-btn,
  .token .purple-btn {
    padding: 9px 41px !important;
  }

  .token .content-col {
    padding-bottom: 64px !important;
  }

  .renting-text-area .box {
    margin-top: -22px;
  }

  /* token */
  /* token */
  .token {
    background-image: url(./images/token-bg-mobile.png);
    background-position: top;
  }

  .vestiing-bg {
    background-image: unset;
  }

  .token .main-heading.text-start {
    text-align: center !important;
  }

  .token-tab-content-box {
    margin-top: -20px;
  }

  .d-flex.vesting-text-line {
    display: block !important;
    text-align: center;
  }

  .vesting-text-line .text-end {
    text-align: center !important;
    margin-top: 10px;
  }

  .vesting-mobile-box-text {
    background: #2B2E35;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
    backdrop-filter: blur(2px);
    border-radius: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  /* end token */
  /* vote */
  .vote .content-top-bar {
    display: block;
    overflow: scroll;
  }

  .vote .vote-top-bar-right-col {
    display: none;
  }

  .vote .content-top-bar .row {
    width: max-content;
  }

  .vote .content-box {
    margin-top: 40px !important;
  }

  .vote-inner-title {
    padding-top: 10px;
  }

  .past-vote .active-questioning,
  .third-vote .active-questioning {
    background-image: unset !important;
    background: #8774FF !important;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  }

  .past-vote-badge,
  .third-last-badge {

    background: #393D45;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
    backdrop-filter: blur(2px);
    border: unset !important;
  }

  .vote-badge {
    padding: 6px 10px !important;
  }

  /* end vote */

  .token-tabs .nav-item {
    width: 100%;
  }

  .token-tabs .tab-title {
    display: flex;
    padding: 17px 24px !important;
    background: rgba(43, 46, 53, 0.8) !important;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24) !important;
    -webkit-backdrop-filter: blur(2px) !important;
    backdrop-filter: blur(2px) !important;
    border-radius: 36px !important;
    margin-top: 12px;
  }

  .nav-tabs .nav-link.active,
  .tab-title.active {
    background: linear-gradient(92.4deg, #7D8EE8 0.57%, #A568FF 97.74%) !important;
  }

  .token-tabs {
    background: unset !important;
    box-shadow: unset !important;
  }

  .token-tabtitle-number {
    text-align: right;
    flex-grow: 100;
  }

  .box.p-5.token-tab-content-box {
    padding: 30px 20px !important;
  }

  .token-tab-content-box {
    background-image: unset !important;
  }

  .token-width-291 {
    width: 100% !important;
  }

  .token-box-form {
    max-width: 300px;
    margin: 0 auto;
  }

  .token-btn {
    margin: 0 auto;
    max-width: 300px;
    display: flex !important;
  }

  .nft-first-tab-content .purple-btn {
    margin-right: 10px;
  }

  .item-modal.modal {
    padding-top: 110px !important;
  }

  /* end token */
  .item-i-btn.mobile-display {
    margin-right: 10px !important;
    display: -webkit-box;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    height: 33px !important;
    width: 39px !important;
  }

  .staking .content-top-bar {
    display: block;
  }

  .staking-topbar-right-col {
    display: none;
  }

  .staking-topbar-left-col {
    text-align: center;
    justify-content: center;
    display: flex;
  }

  .staking-col .font-32,
  .staking-col .font-30 {
    font-size: 24px !important;
    line-height: 27px !important;
  }

  .staking-card-one button,
  .staking-card-two button,
  .staking-card-three button {
    padding: 8px 28px !important;
  }

  .staking-card-two {
    background-position-y: 5% !important;
  }

  .staking .content-col {
    padding-bottom: 53px;
  }

  .staking .mobile-display .green-btn {
    padding: 8px 28px !important;
  }
}

@media(max-width:400px) {
  .home {

    background-position-y: 35%;
  }

  .my-wallet .nft-button-area button,
  .my-wallet .nft-tab-content .purple-btn {
    font-size: 14px !important;
    padding: 8px 20px !important;
  }

  .starter-play-card {
    height: 180px;
  }

  .play-game-sub-text {
    font-size: 13px;
  }

  .play-starter-second-box-img-mobile.d-none {
    top: -58px;
  }
}

@media(max-width:321px) {
  img.play-starter-second-box-img-mobile.d-none {
    display: none !important;
  }

  .nft-inner-box .nft-text-area,
  .nft-inner-box img {
    width: 100% !important;
  }

  .swicth-network-main {
    padding-bottom: 50px;
  }

  .marketplace .market-mobile-price {
    position: unset !important;
  }

  .marketplace .marketplace-first-tab-content .nft-inner-box {
    height: auto;
  }

  /*connect */
  .connect-inner-card.d-flex {
    display: block !important;
  }

  p.connect-text.text-center {
    text-align: center !important;
  }

  /* end connect */
  .market-first-tab-modal-row {
    display: block !important;
    padding-top: 32px;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }


  .item-modal .btn-close {
    top: 3% !important;
  }

  .market-first-tab-modal-row .col-6 {
    width: 100% !important;
    padding-left: 0px !important;
  }

  .market-first-tab-modal-row .item-modal-right-col {
    margin-top: 20px;
  }

  /* home */
  .home {
    background-position-y: 55%;
  }

  /* end home */
  /* nft wallet */
  .my-wallet .nft-inner-box {
    height: auto !important;
  }

  .nft-inner-box,
  .nft-button-area {
    display: block;
  }

  .nft-button-area button {
    margin-bottom: 10px;
  }

  .tab-title {
    font-size: 15px !important;
  }

  .main-heading {
    font-size: 32px;
    line-height: 40px;
  }

  /* end nft wallet */
  /* play */
  .play-starter-right-col1,
  .play-starter-right-col2 {
    width: 100% !important;
  }

  .play-starter-left-col-main {
    padding: 0px !important;
  }


  /* end play */
  /* stacking */
  .transation-yellow-line-box .d-flex,
  .transation-green-line-box .d-flex,
  .transation-purple-line-box .d-flex {
    display: block !important;
  }

  .starter-play-card {
    height: auto !important;
  }

  .d-flex.staking-mobile-first-box {
    display: block !important;
  }

  .staking-mobile-first-box img {
    margin: auto !important;
    justify-content: center !important;
    display: flex;
    margin-bottom: 10px;
    margin-top: -26px !important;
  }

  /* end stacking */
  /* vote */

  .top-timing {
    display: flex;
  }

  .top-timing.top-0 {
    top: 15px !important;
  }

  .content-box {
    padding-top: 50px !important;
  }

  /* end vote */
}

@media (max-width:900px) and (orientation: landscape) {
  .vote-top-bar-right-col.col-xl-3.col-lg-3.col-md-3 {
    display: none;
  }

  .vote-top-bar-left-col.col-xl-9.col-lg-9.col-md-9 {
    text-align: center;
    justify-content: center;
    display: flex;
  }

  .item-i-btn.mobile-display {
    display: -webkit-box;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    margin-right: 10px !important;
  }

  .item-modal.modal {
    padding-top: 110px !important;
  }

  img.connect-zig-two {
    right: 0px;
  }

  .swicth-network-main {
    height: unset;
    min-height: unset;

  }

  .switch-main-box {
    position: unset !important;
    transform: unset !important;
    padding-top: 50px;
    padding-bottom: 100px;
  }

  .switch-zig-two {
    right: 0px;
  }

  img.connect-zig-one {
    left: 0px;
  }

  .profile-cal-left-col {
    height: 500px;
    width: 100%;
    padding-top: 300px;
  }

  .change-pass-hamer,
  .change-pass-axe {
    display: none;
  }

  .create-account {
    background-image: unset;
  }

  /* .profile-calculator {
    margin-top: -0px;
  } */
  /*navigation */
  .nav-modal .modal-body {
    max-width: 500px;
  }

  .nav-modal {
    top: 32% !important;
  }

  /* end navigtain */
  /* nft wallet */
  .d-md-block.nft-wallet-main-title {
    display: none !important;
  }

  .nft-wallet-sort-main {
    text-align: center;
    width: 100% !important;
  }

  /* end nft wallet */

}

@media (max-width:900px) and (min-width:600px) and (orientation: landscape) {
  .nft-card-main {
    width: 50% !important;
  }

  .nft-button-area button {
    margin-bottom: 10px;
  }
}

@media(min-width:300px) {}

/* end custom ccss */

/* start Custom vote css */
.vote .main-inner {
  background-image: url(./images/vote-bg-2.png);
  background-size: contain;
}

.vote-inner-title {
  font-family: "Poppins-bold";
  font-weight: 600;
  font-size: 32px;
  color: var(--purple);
}

.vote-inner-content {
  font-family: "Poppins-medium";
  font-weight: 500;
  font-size: 18px;
  color: white;
}

.btn-success {
  font-family: "Poppins-bold";
  font-weight: 600;
  font-size: 16px;
  color: white;
  padding: 10px 49px;
  background: #1bd19b;
  border-radius: 14px;
  border: 0px solid transparent;
  transition: all 0.2s ease-in-out;
}

/* .btn-success:hover {
  background: #389579;
} */
.btn-danger {
  font-family: "Poppins-bold";
  font-weight: 600;
  font-size: 16px;
  color: white;
  padding: 10px 49px;
  background: #ff4163;
  border-radius: 14px;
  border: 0px solid transparent;
  transition: all 0.2s ease-in-out;
}

.inner-hr {
  display: block;
  height: 4px;
  background: linear-gradient(90deg, #7d8ee8 0%, #a568ff 100%);
}

.box .box-dark-num {
  min-width: 60px !important;
  background: #2b2e35;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(2px);
  border-radius: 16px;
  font-family: "Poppins-bold";
  font-weight: 600;
  font-size: 32px;
  color: white;
  cursor: pointer;
  text-align: center;
  padding: 8px 0px;
}

.box .box-dark-middle {
  min-width: 90px !important;
  background: #2b2e35;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(2px);
  border-radius: 16px;
  font-family: "Poppins-medium";
  font-weight: 500;
  font-size: 20px;
  color: white;
  cursor: pointer;
  text-align: center;
  padding: 16px 10px;
}

.box .box-dark-content {
  background: #2b2e35;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(2px);
  border-radius: 16px;
  font-family: "Poppins-medium";
  font-weight: 500;
  font-size: 16px;
  color: white;
  cursor: pointer;
  width: 100%;
  padding: 19px 33px;
}

.active-questioning {
  background-image: linear-gradient(102.25deg,
      #8774ff 16.25%,
      #a568ff 58.92%) !important;
}

.active-questioning-purple {
  background: #8774ff !important;
}

.border-blue {
  border: 2px solid rgba(135, 116, 255, 0.6);
}

.content-box {
  padding: 30px 53px;
  margin-top: -31px;
}

.top-timing {
  font-family: "Poppins-Medium";
  font-weight: normal;
  font-size: 18px;
}

.top-timing button:hover {
  transform: unset !important;
  cursor: default;
}

.vote-tooltip .tooltip-inner {
  background-image: url(./images//vote-tooltip-img.png) !important;
  padding: 10px 25px;
  font-family: 'Poppins-Medium';
  font-size: 16px;
  line-height: 24px;
  background-color: unset !important;
  top: 25px !important;
  position: relative;
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  height: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  left: 50px;
}

.vote-tooltip .tooltip-arrow {
  display: none !important;
}

/* end Custom vote css */
@media (max-width: 1200px) {
  .content-box {
    margin-top: 0;
  }
}

@media (max-width: 992px) {
  .box .box-dark-middle {
    position: absolute;
    min-width: 70px !important;
    border-radius: 8px;
    font-size: 14px;
    padding: 4px 0px;
    top: -20px;
    right: -10px;
    z-index: 111;
  }

  .purple-btn {
    padding: 8px 59px !important;
    font-size: 18px;
  }

  .box .box-dark-content {
    padding: 10px 18px;
    font-size: 14px;
  }

  .box .box-dark-num {
    min-width: 48px !important;
    font-size: 20px;
  }

  .vote-inner-content {
    font-size: 14px;
  }

  .vote-inner-title {
    font-size: 18px;
  }

  .btn-danger {
    font-size: 14px;
    padding: 8px 26px;
  }

  .btn-success {
    font-size: 14px;
    padding: 8px 26px;
  }

  .top-timing {
    font-size: 12px;
  }

  .content-box {
    padding: 24px 42px;
  }

  /* .content-top-bar {
    overflow-x: scroll;
  } */
  .vote .content-top-bar {
    display: block;
  }

  /* .vote .content-top-bar .row {
    width: 992px;
  } */
}

@media (max-width: 768px) {
  .box .box-dark-num {
    min-width: 46px !important;
  }

  .box .box-dark-content {
    padding: 10px 14px;
  }

  .content-box {
    padding: 20px 20px;
  }
}

.btn-demo {
  background: #2b2e35;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(2px);
  border-radius: 36px;
  color: white;
  border: 0px solid transparent;
  padding: 10px 40px;
}

.btn-coloured {
  background: #3263d7;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(2px);
  border-radius: 36px;
  color: white;
  border: 0px solid transparent;
  padding: 10px 40px;
}

.btn-yellow {
  background: #2b2e35;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(2px);
  border-radius: 36px;
  color: white;
  border: 0px solid transparent;
  padding: 10px 40px;
}

.btn-yellow:hover {
  background: #ffa800;
}





.horizontal-slider {
  height: 4px;
  background: #fff;
}

.example-thumb.example-thumb-0,
.example-thumb.example-thumb-1 {
  top: 15px;
  color: rgb(255, 255, 255);
  font-family: 'Poppins-Bold';
  font-size: 20px;
  line-height: 30px;
  color: var(--white);

}

.example-track.example-track-1 {
  background: #8774FF;
  height: 4px;
}

.example-track.example-track-1:after {
  background-color: var(--purple);
  border-radius: 50%;
  content: " " !important;
  height: 15px;
  position: absolute;
  width: 15px;
  top: -5px !important;
  right: 0px;
}

.example-track.example-track-0:before {
  background-color: var(--purple);
  border-radius: 50%;
  content: " " !important;
  height: 15px;
  position: absolute;
  width: 15px;
  top: -5px !important;
  right: 0px;
}

@media(max-width:992px) {
  .main-inner.p-4 {
    padding-top: 0px !important;
  }
}

@media(max-width:600px) {
  .main {
    background-image: url(./images/mobile-main-dark-bg.png);
    background-size: cover;
  }

  .home .main-inner {
    background-image: url(./images/home-mobile-bg-2.png);

    background-position-y: 77%;
  }

  .main-inner {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0px !important;
  }

  .main-inner.pt-4 {
    padding-top: 0px !important;
  }

  .p-4.pb-0.pt-0.pt-xl-0.pt-lg-0.pt-md-0.common-main {
    padding: 0px !important;
  }

  .my-wallet .main-inner {
    background-image: url(./images/my-wallet-bg-mobile-2.png);
    background-position-y: 11%;
  }

  .marketplace .main-inner {
    background-image: url(./images/marketplace-bg-mobile-2.png);
    background-position-y: 2%;
  }

  .play .main-inner {
    background-image: url(./images/play-mobile-circle.png) !important;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: 66%;
  }

  .renting .main-inner {
    background-image: url(./images/renting-mobile-bg-2.png);
    background-position-y: 37%;
  }

  .token .main-inner {
    background-image: url(./images/token-mobile-bg-2.png);
    background-position-y: 71%;
    background-position-x: unset !important;
  }

  .vote .main-inner {
    background-image: url(./images/vote-mobile-bg-2.png);
    background-position-y: 20%;
    background-repeat: no-repeat;
  }

  .staking .main-inner {
    background-image: url(./images/staking-bg-mobile-2.png);
    background-position-y: 24%;
  }

  .marketplace .modal.show {
    z-index: 999999999 !important;
  }
}

@media(max-width:397px) {
  .home .main-inner {
    background-position-y: 72%;
  }

  .open-filter-box .filter-btn {
    font-size: 13px !important;
  }

  .play-starter-left-col h1 {
    font-size: 38px;
  }

  .my-wallet .nft-inner-box {
    height: 158px;
  }
}

@media(max-width:321px) {
  .home .purple-gradient-box {
    padding: 16px;
  }

  #filter-inner .filter-btn-col .filter-btn {
    padding: 8px 19px !important;
  }

  .sell-modal-inner-box {
    height: auto;
  }

  .my-wallet .nft-inner-box {
    padding-right: 0px !important;

  }

  .marketplace .nft-inner-box {
    padding: 17px;
  }

  .sell-modal-body .row {
    display: block;
    margin: 0px !important;
  }

  .sell-modal-left-col.col-6,
  .sell-modal-right-col.col-6 {
    width: 100% !important;
  }

  .pe-0.sell-modal-left-col,
  .sell-modal-body .mobile-display {
    padding-right: 0px !important;
    padding: 0px !important;
  }

  .sell-modal-body {
    padding-top: 50px !important;
  }

  .mobile-logo {
    width: 110px;
  }

  .modal-open .mobile-logo {
    width: 120px;
  }

  .mobile-toggle-btn img {
    width: 18px;
  }
}

table.table.table-bordered {
  color: #fff;
}

tbody:before {
  content: "";
  height: 22px;
  display: block;
  width: 100%;
}

thead th {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

thead th,
tbody td {
  margin-bottom: 0px !important;
  background: rgba(43, 46, 53, 0.6) !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24) !important;
}

thead th:first-child {
  border-radius: 30px 0px 0px 30px;
}

thead th:last-child {
  border-radius: 0px 30px 30px 0px;
}

table tbody tr:first-child td:first-child {
  border-top-left-radius: 30px;
}

table tbody tr:first-child td:last-child {
  border-top-right-radius: 30px;
}

table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 30px;
}

table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 30px;
}

table tbody tr:first-child td {
  padding-top: 31px;

}

table tbody tr td {
  vertical-align: middle;
}

table tbody tr:last-child td {
  padding-bottom: 28px;
}

th:nth-child(3)::after,
th:nth-child(4)::after {
  content: url(./images/Polygon-up.png);
  /* right: 0px !important; */
  /* position: absolute; */
  padding-left: 10px;
}

th:nth-child(3).sorting-desc::after,
th:nth-child(4).sorting-desc::after {
  content: url(./images/Polygon-down.png);
}

td:last-child {
  width: 217px;
}

table .green-btn {
  padding: 8px 32px !important;
}

.staking-table .table>tbody td {
  text-align: left;
  padding-left: 30px;
  padding-right: 30px;
}

.staking-table .table>tbody td:last-child {
  text-align: center;
}

/* tbody tr:first-child td:first-child:before {
  content: "" !important;

  background: #FFD600;
  width: 4px !important;
  height: 16px !important;
  position: absolute;
  top: 43px;
  left: 27px;
} */
tbody tr td:first-child {
  /* position: relative; */
  padding-left: 30px !important;
}

.sell-modal-left-col img {
  height: 100%;
  object-fit: contain;
}

.left-side.text-start img {
  padding-bottom: 0.5rem !important;
  padding-top: 0.5rem !important;
}

.switch .main-inner,
.connect .main-inner {
  display: none;

}

.vote .content-top-bar::-webkit-scrollbar {
  width: 0px;
}

@media(max-width:360px) {
  .my-wallet .nft-button-area button {
    font-size: 13px !important;
  }
}

@media(min-width:1900px) {
  .navigation-col.open {
    width: 5% !important;
  }
}

@media(min-width:2560px) {
  .navigation-col.open {
    width: 4% !important;
  }
}